import React, { useEffect, useState } from "react";
import { Form, Button, Card, Container, ListGroup, DropdownButton, Dropdown, Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { useAuth } from "../../contexts/AuthContext";
import { auth, fires } from "../../firebase";
import { useParams } from "react-router-dom";
import firebase from "firebase";
import { CSVLink, CSVDownload } from "react-csv";
import { DataGrid } from "@mui/x-data-grid";

export default function AdminAllOrganizations() {
  const maxW = isMobile ? "100%" : "80%";
  const [groups, setAllGroups] = useState([])
  const [allEvents, setAllEvents] = useState([])

  const [csvOrgData,setCSVOrgData] = useState([
    ["example", "example", "email"],
    ["a", "a", "a@gmail.com"],
    ["b", "b", "b@gmail.com"],
    ["c", "c", "c@gmail.com"]
  ]);

  const [csvEventData,setCSVEventData] = useState([
    ["example", "example", "email"],
    ["a", "a", "a@gmail.com"],
    ["b", "b", "b@gmail.com"],
    ["c", "c", "c@gmail.com"]
  ]);

  const eventsCol = [
      { field: "id", headerName: "ID" },
      { field: "title", headerName: "Name",},
    { field: "startDate", headerName: "Start Date",},
    { field: "endDate", headerName: "End Date",},
    { field: "orgId", headerName: "Org ID",},
    { field: "orgName", headerName: "Org Name",},
    { field: "userCount", headerName: "Members",},
    { field: "ticketCount", headerName: "Tickets",},
    { field: "viewCount", headerName: "Views",}
]

const groupsCol = [
    { field: "id", flex: 0.2, headerName: "ID" },
    { field: "name", flex: 0.5, headerName: "Name" },
    { field: "event_count", flex: 0.3, headerName: "Events" },
    { field: "created", flex: 0.2, headerName: "Created" },
]


  useEffect(() => {
      fires
      .collection('admin')
      .doc(auth.currentUser.uid)
      .get()
      .then((snapshot)=>{
          if (snapshot.exists){
            loadAllGroups()
            loadAllEvents()
          }
          else{
            window.location.href = "/404"
          }
      })
  },[])

  function loadAllEvents(){
    fires
    .collection('events')
    .where('summary.views','>=',0)
    .orderBy('summary.views', 'desc')
    .get().then((snapshot) => {
        let vEvents = []
        let eventCSVD = [['ID','Name','Start Date','End Date','Org ID','Org Name','Users','Tickets','Views']]
        snapshot.docs.forEach((doc) => {
            let eveId = doc.id
            let eveData = doc.data()

            var eveTitle = ""
            var eveStartD = ""
            var eveEndD = ""
            var eveOrgId = ""
            var eveOrgName = ""
            var eveUserCount = 0
            var eveViewCount = 0
            var eveTicketCount = 0

            if (eveData.title != null){
                eveTitle = eveData.title
            }
            if (eveData.startDate != null){
                eveStartD = eveData.startDate.toDate().toISOString()
            }
            if (eveData.endDate != null){
                eveEndD = eveData.endDate.toDate().toISOString()
            }
            if (eveData.orgId != null){
                eveOrgId = eveData.orgId
            }
            if (eveData.user != null){
                if (eveData.user.userOrg != null){
                    eveOrgName = eveData.user.userOrg
                }
            }
            if (eveData.summary != null){
                if (eveData.summary.users != null){
                    eveUserCount = eveData.summary.users
                }
                if (eveData.summary.tickets != null){
                    eveTicketCount = eveData.summary.tickets
                }
                if (eveData.summary.views != null){
                    eveViewCount = eveData.summary.views
                }
            }

            vEvents.push({
                id: eveId,
                title: eveTitle,
                startDate: eveStartD,
                endDate: eveEndD,
                orgId: eveOrgId,
                orgName: eveOrgName,
                userCount: eveUserCount,
                ticketCount: eveTicketCount,
                viewCount: eveViewCount,
            })
            eventCSVD.push([
                eveId,
                eveTitle,
                eveStartD,
                eveEndD,
                eveOrgId,
                eveOrgName,
                eveUserCount,
                eveTicketCount,
                eveViewCount
            ])

        })
        vEvents = vEvents.sort((a,b) => {
            return b.viewCount - a.viewCount
        })

        setAllEvents(vEvents)
        setCSVEventData(eventCSVD)
        
    })
    .catch((e) => {
        alert(e.message)
    })

  }



  function loadAllGroups(){
    fires
    .collection('groups')
    .where('status','==','live')
    .orderBy('event_count', 'desc')
    .get().then(snapshot => {
        let groupsRe = []
        let orgCSVD = [['ID','Name','Events','Created']]
        snapshot.docs.forEach((doc) => {
            let groupId = doc.id
            let groupData = doc.data()

            let groupEventCount = 0
            if (groupData.event_count != null){
                groupEventCount = groupData.event_count
            }
            let groupCreated = ""
            if (groupData.timestamp != null){
                groupCreated = groupData.timestamp.toDate().toISOString()
            }
            groupsRe.push({
                id: groupId, 
                name: groupData.name, 
                event_count: groupEventCount, 
                created: groupCreated})
            orgCSVD.push([
                groupId,
                groupData.name,
                groupEventCount,
                groupCreated])
        })
        setAllGroups(groupsRe)
        setCSVOrgData(orgCSVD)
        
    })
  }

  async function generateEventCount(groupId){
      if (groupId != null && groupId != ""){
          const eventSnap = await fires.collection('events').where('orgId', '==', groupId).where('status','==','live').get()
          const eventCount = eventSnap.docs.length
          if (eventCount > 0) {
            fires.collection('groups').doc(groupId).update({event_count: eventCount}).then((d)=>{
                
            })
          }
      }
  }


  return (
    <>
      <Helmet>
        <title>Admin Organization</title>
        <meta property="og:title" content="View Admins" />
        <meta
          property="og:description"
          content="View Admins"
        />
        <meta property="og:url" content={"https://evtgo.com/"} />
        <meta
          property="og:site_name"
          content="View Admins"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
      </Helmet>
      <Container
        className="d-flex justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundColor: "white",
        }}
      >
        <div style={{ width: maxW, marginTop: "25px", marginBottom: "25px" }}>
          <Card className="border-0" style={{ backgroundColor: "transparent" }}>
            <Card.Body>
            <div style={{ marginTop: "25px", marginBottom: "45px" }}>
                <Card.Title 
                    className="text-center" 
                    style={{fontSize: '20px', fontWeight: 'bold'}}>
                  {groups.length}个活动方
                </Card.Title>
                <div className="d-flex align-items-center justify-content-center">
                <CSVLink 
            filename={"total-orgs-eventgo" + ".csv"}
            data={csvOrgData}
            className="btn btn-dark"
            style={{color: 'white'}}>下载活动方数据</CSVLink>
            </div>

              </div>
              <div style={{marginTop: 25, marginBottom: 25}}>
              <DataGrid 
        checkboxSelection 
        editMode="row"
        sx={{ overflowX: 'scroll', marginTop: '15px' }}
        rows={groups} 
        columns={groupsCol} />
              </div>

                <div>
                <div style={{ marginTop: "25px", marginBottom: "45px" }}>
                <Card.Title 
                    className="text-center" 
                    style={{fontSize: '20px', fontWeight: 'bold'}}>
                  {allEvents.length}个活动
                </Card.Title>
                <div className="d-flex align-items-center justify-content-center">
                <CSVLink 
            filename={"total-events-eventgo" + ".csv"}
            data={csvEventData}
            className="btn btn-dark"
            style={{color: 'white'}}>下载活动数据</CSVLink>
                </div>
              </div>
                <DataGrid 
        checkboxSelection 
        editMode="row"
        sx={{ overflowX: 'scroll', marginTop: '15px' }}
        rows={allEvents} 
        columns={eventsCol} />
                </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
