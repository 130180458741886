import React, { useEffect, useState } from "react";
import { Form, Button, Card, Container, ListGroup, DropdownButton, Dropdown } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { useAuth } from "../../contexts/AuthContext";
import { auth, fires } from "../../firebase";
import { useParams } from "react-router-dom";
import firebase from "firebase";

export default function AdminOrderDetails() {
  const maxW = isMobile ? "100%" : "40%";
  const [orderWording, setOrderWording] = useState('')
  const [orderData, setOrderData] = useState({
    amount: 0,
    eventId: '',
    title: '',
    id: '',
    userName: '',
    userEmail: '',
    userUID: '',
    dateSelected: '',
    type: '',
    status: '',
    statusMessage: '',
    cartItems: [],

  })
  let { orderId } = useParams();

  useEffect(() => {
      fires
      .collection('admin')
      .doc(auth.currentUser.uid)
      .get()
      .then((snapshot)=>{
          if (snapshot.exists){
            //load order with Order ID
            if (orderId != ""){
              loadOrder(orderId)
            }
            else{
                window.location.href = "/404"
            }
            
          }
          else{
            window.location.href = "/404"
          }
      })
  },[])

  function loadOrder(orderId) {
      fires.collection('orders').doc(orderId).get().then((orderSnap) => {
          if (orderSnap.exists){
              const orderD = orderSnap.data()
              if (orderD.cartItems != null){

              }
              setOrderData({...orderD})

              //setOrderWording
              let orderTickets = ""
              const orderW = `Dear ${orderD.userName},\n Thank you for purchasing tickets via Event Go. Here is an overview for your recent purchase:
              \n\n
              Booking Reference: ${orderId}\n
              Tickets:\n
              ${orderTickets}\n
              Date: ${(new Date()).toLocaleDateString('en-US',{timeZone: 'America/Toronto'})}\n
              \n\n
              Important Information\n
              \n
              Please arrive at least 10 minutes before your scheduled time.\n
              Don’t forget to bring a valid ID and your booking confirmation for entry.\n
              For any changes or questions about your booking, contact us at tickets@evtgo.com\n
              \n
              Thank you,\n\n
              
              Event GO\n
              Love your events`
              setOrderWording(orderW)
          }
          else{
            window.location.href = "/404"
          }
      })
  }

  return (
    <>
      <Helmet>
        <title>Admin Order Details</title>
        <meta property="og:title" content="View Admins" />
        <meta
          property="og:description"
          content="View Admins"
        />
        <meta property="og:url" content={"https://evtgo.com/"} />
        <meta
          property="og:site_name"
          content="View Admins"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
      </Helmet>
      <Container
        className="d-flex justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundColor: "white",
        }}
      >
        <div style={{ width: maxW, marginTop: "25px", marginBottom: "25px" }}>
          <Card className="border-0" style={{ backgroundColor: "transparent" }}>
            <Card.Body className="align-items-center justify-content-center">
              <div style={{ marginTop: "25px", marginBottom: "45px" }}>
                <Card.Title 
                    className="text-center" 
                    style={{fontSize: '25px', fontWeight: 'bold'}}>
                  Order Information
                </Card.Title>
              </div>
              <ListGroup>
                    <ListGroup.Item>
                      <div className="fw-normal">Event Name</div>
                      <div className="fw-bold">{orderData.title}</div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                      <div className="fw-normal">Customer Name</div>
                      <div className="fw-bold">{orderData.userName}</div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                      <div className="fw-normal">Customer Email: </div>
                      <div className="fw-bold">{orderData.userEmail}</div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                      <div className="fw-normal">Date Selected</div>
                      <div className="fw-bold">{orderData.dateSelected}</div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                      <div className="fw-normal">Amount</div>
                      <div className="fw-bold">{orderData.amount.toLocaleString('en-US',{style: 'currency', currency: 'CAD'})}</div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                      <div className="fw-normal">Items</div>
                      <div className="fw-bold">{orderData.cartItems.length}</div>
                  </ListGroup.Item>
                  {
                      orderData.cartItems.map((item) => {
                        return <ListGroup.Item>
                            <div className="fw-bold">{item.priceDescription}</div>
                            <div className="fw-normal">{item.quantity} X {item.price}</div>
                        </ListGroup.Item>
                      })
                  }
                  <ListGroup.Item>
                      <div className="fw-normal">Status</div>
                      <div className="fw-bold">{orderData.status}</div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                      <div className="fw-bold">Your Tickets for {orderData.title}</div><br/>
                      <div className="fw-normal">{`Dear ${orderData.userName},`}</div><br/>
                      <div className="fw-normal">{`Thank you for purchasing tickets via Event Go. Here is an overview for your recent purchase:`}</div><br/>
                      <div className="fw-normal">{`Booking Reference: ${orderId}\n`}</div>
                      <div className="fw-normal">{`Tickets: `}</div>
                      {
                      orderData.cartItems.map((item) => {
                        return <div className="fw-normal">{item.priceDescription} (Qty: {item.quantity})</div>
                      })
                  }
                      <div className="fw-normal">{`Date Selected: ${orderData.dateSelected}\n`}</div><br/>
                      <div className="fw-normal">{`Important Information`}</div>
                      <div className="fw-normal">{`Please arrive at least 10 minutes before your scheduled time.`}</div>
                      <div className="fw-normal">{`Don’t forget to bring a valid ID and your booking confirmation for entry.`}</div>
                      <div className="fw-normal">{`For any changes or questions about your booking, contact us at tickets@evtgo.com`}</div><br/>
                      <div className="fw-normal">{`Best Regards, `}</div><br/>
                      <div className="fw-bold">{`Event Go`}</div>
                      <div className="fw-normal">{`Love Your Events`}</div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                      <div className="fw-normal">Change Status</div>
                      <div>
                      <DropdownButton
                            className="border-0"
                            title={orderData.status}
                            style={{marginTop: '15px'}}
                            id="dropdown-menu-align-right"
                            variant="outline-dark"
                            onSelect={(e) => {
                              if (e == 'email-sent'){
                                fires.collection('orders').doc(orderId).update({
                                    status: 'email-sent',
                                    statusMessage: 'Order ticket has been sent to email', 
                                    statusFromUID: auth.currentUser.uid
                                }).then(()=>{
                                    window.location.href = "/dashboard#admin-tour-orders"
                                }).catch((e)=>{
                                    alert(e)
                                })
                                
                              }
                              else if (e == 'order-completed'){
                                fires.collection('orders').doc(orderId).update({
                                    status: 'completed',
                                    statusMessage: 'Order ticket has been sent to email', 
                                    statusFromUID: auth.currentUser.uid
                                }).then(()=>{
                                    window.location.href = "/dashboard#admin-tour-orders"
                                }).catch((e)=>{
                                    alert(e)
                                })
                                
                              }
                              else if (e == 'pending'){
                                fires.collection('orders').doc(orderId).update({
                                    status: 'pending',
                                    statusMessage: 'Order ticket has been sent to email', 
                                    statusFromUID: auth.currentUser.uid
                                }).then(()=>{
                                    window.location.href = "/dashboard#admin-tour-orders"
                                }).catch((e)=>{
                                    alert(e)
                                })
                              }
                            }}
                          >
                            <Dropdown.Item
                              eventKey="email-sent"
                              style={{ color: "black" }}
                            >
                              Change to Email Sent
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="order-completed"
                              style={{ color: "black" }}
                            >
                              Change to Order Completed
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="pending"
                              style={{ color: "black" }}
                            >
                              Change to Pending
                            </Dropdown.Item>
                        </DropdownButton>
                      </div>
                  </ListGroup.Item>
                  
              </ListGroup>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
