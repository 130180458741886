import React, { useEffect, useState } from "react";
import { Form, Button, Card, Container, ListGroup, DropdownButton, Dropdown, Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { useAuth } from "../../contexts/AuthContext";
import { auth, fires } from "../../firebase";
import { useParams } from "react-router-dom";
import firebase from "firebase";

export default function AdminPageViews() {
  const maxW = isMobile ? "100%" : "80%";
  const [searchKeywords, setSearchKeywords] = useState([])
  const [tourVisitedCount, setTourVisitedCount] = useState({})
  const [eventRef, setEventRef] = useState({})
  const [pageViews, setPageViews] = useState([])
  const [pageViewData, setPageViewData] = useState({
      uniqueIp: 0,
      uniqueEventVisit: 0,
      mostEventVisit: '',
      tourVisit: 0,
      uniqueTourIP: 0,
      mostVisitedTour: '',
  })
  const [userCreated, setUserCreated] = useState(0)
  const [orderCreated, setOrderCreated] = useState(0)
  const [tourOrderCreated, setTourOrderCreated] = useState(0)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  useEffect(() => {
      fires
      .collection('admin')
      .doc(auth.currentUser.uid)
      .get()
      .then((snapshot)=>{
          if (snapshot.exists){
            loadAllEvents()
            loadAllSearch()
          }
          else{
            window.location.href = "/404"
          }
      })
  },[])

  function loadAllEvents(){
    fires
    .collection('events')
    .where('status','==','live')
    .get().then(snapshot => {
        let eveRef = {}
        snapshot.docs.forEach((doc) => {
            let eveId = doc.id
            let eveData = doc.data()
            let eveTitle = eveData.title
            let eveType = ""
            if (eveData.type == 'tour'){
                eveType = "tour"
            }
            else{
                eveType = "event"
            }
            eveRef[eveId] = {title: eveTitle, type: eveType}
        })
        setEventRef(eveRef)
        changeCurrentDate(0)
    })
  }

  function loadAllSearch(){
    fires
    .collection('search')
    .orderBy('timestamp','desc')
    .get()
    .then((snapshot) => {
        let keyW = []
        snapshot.docs.forEach((searchQ) => {
            let searchId = searchQ.id
            let searchData = searchQ.data()
            if (searchData.keywords != null){
                if (searchData.keywords != ""){
                    keyW.push(searchData.keywords)
                }
            }
        })
        setSearchKeywords(keyW)
    })
  }

  function loadUserCreated(startD,endD){
    fires
      .collection('users')
      .where('timestamp','>=',firebase.firestore.Timestamp.fromDate(startD))
      .where('timestamp','>=',firebase.firestore.Timestamp.fromDate(endD))
      .orderBy('timestamp','desc')
      .get()
      .then((userSnap) => {
            let userCount = 0
          userSnap.docs.forEach((doc)=>{
              const userD = doc.data()
              if (userD.isAnonymous != null){
                  if (!userD.isAnonymous) {
                      userCount += 1
                  }
              }
          })
          setUserCreated(userCount)
      })
  }

  function loadOrderTotal(startD,endD){
    const todayStart = new Date(new Date().setHours(0, 0, 0, 0))
    fires
      .collection('orders')
      .where('timestamp','>=',firebase.firestore.Timestamp.fromDate(startD))
      .where('timestamp','<=',firebase.firestore.Timestamp.fromDate(endD))
      .orderBy('timestamp','desc')
      .get()
      .then((orderSnap) => {
          let tOrderC = 0
          orderSnap.docs.forEach(doc=>{
              let docData = doc.data()
              if (docData.type == "tour") {
                  tOrderC += 1
              }
          })
          setTourOrderCreated(tOrderC)
          setOrderCreated(orderSnap.docs.length)
      })
  }

  async function changeCurrentDate(state){
      const todayStart = new Date(startDate.setHours(0, 0, 0, 0))
      const todayEnd = new Date(endDate.setHours(23, 59, 59, 999))
      if (state == 1){
        let nextDay = new Date(todayStart)
        let nextDayEnd = new Date(todayEnd)
        nextDay.setDate(nextDay.getDate() + 1)
        nextDayEnd.setDate(nextDayEnd.getDate() + 1)
        setStartDate(nextDay)
        setEndDate(nextDayEnd)
        loadPageViews(nextDay,nextDayEnd)
        loadUserCreated(nextDay,nextDayEnd)
        loadOrderTotal(nextDay,nextDayEnd)
      }
      else if (state == -1){
        let yesterday = new Date(todayStart)
        let yesterdayEnd = new Date(todayEnd)
        yesterday.setDate(yesterday.getDate() - 1)
        yesterdayEnd.setDate(yesterdayEnd.getDate() - 1)
        setStartDate(yesterday)
        setEndDate(yesterdayEnd)
        loadPageViews(yesterday,yesterdayEnd)
        loadUserCreated(yesterday,yesterdayEnd)
        loadOrderTotal(yesterday,yesterdayEnd)
      }
      else{
        setStartDate(todayStart)
        setEndDate(todayEnd)
        loadPageViews(todayStart, todayEnd)
        loadUserCreated(todayStart,todayEnd)
        loadOrderTotal(todayStart,todayEnd)
      }
  }

  function loadPageViews(startD, endD){  
      fires
      .collection('page_views')
      .where('timestamp','>=',firebase.firestore.Timestamp.fromDate(startD))
      .where('timestamp','<=',firebase.firestore.Timestamp.fromDate(endD))
      .orderBy('timestamp','desc')
      .get()
      .then((viewSnap) => {
          let pageVs = []
          let pageVNa = {
            uniqueIp: 0,
            uniqueEventVisit: 0,
            mostEventVisit: '',
            tourVisit: 0,
            uniqueTourIP: 0,
            mostVisitedTour: '',
          }
          let uniqueIps = []
          let uniqueTourIps = []
          let tourVisitCount = {}
          viewSnap.docs.forEach((doc) => {
              let viewD = doc.data()
              let eventId = ""
              let lon = 0.0
              let lat = 0.0
              let city = ""
              let region = ""
              if (viewD.eventId != null){
                  eventId = viewD.eventId
                  if (eventId != ""){
                      if (Object.keys(eventRef).includes(eventId)){
                          const tType = eventRef[eventId].type
                          let eveTitle = eventRef[eventId].title
                          
                          if (tType == 'tour'){
                              pageVNa.tourVisit += 1
                              if (!uniqueTourIps.includes(viewD.currentIP)){
                                  uniqueTourIps.push(viewD.currentIP)
                              }

                              if (eveTitle != null && eveTitle != ""){
                                if (Object.keys(tourVisitCount).includes(eveTitle)){
                                    tourVisitCount[eveTitle] += 1
                                }
                                else{
                                    tourVisitCount[eveTitle] = 1
                                }
                              }
                          }
                      }
                  }
              }
              
              if (viewD.lat != null){
                lat = viewD.lat
                }
              if (viewD.lon != null){
                lon = viewD.lon
              }
              if (viewD.city != null){
                city = viewD.city
              }
              if (viewD.region != null){
                region = viewD.region
              }
              let viewDa = {
                  timestamp: viewD.timestamp,
                  currentIP: viewD.currentIP,
                  eventId: eventId,
                  lon: lon,
                  lat: lat,
                  location: city + ", " + region,
              }
              pageVs.push(viewDa)
              if (!uniqueIps.includes(viewD.currentIP)){
                  uniqueIps.push(viewD.currentIP)
              }
              
          })
          setPageViews(pageVs)
          pageVNa.uniqueIp = uniqueIps.length
          pageVNa.uniqueTourIP = uniqueTourIps.length
          
          

          const tourVisited = Object.keys(tourVisitCount).sort((astr,bstr)=>{
              let aCount = tourVisitCount[astr]
              let bCount = tourVisitCount[bstr]
              return bCount - aCount
          })
          if (tourVisited.length > 0){
            pageVNa.mostVisitedTour = tourVisited[0]
          }
          setPageViewData(pageVNa)
          setTourVisitedCount(tourVisitCount)
      })
  }

  function modeStr(arr){
    return arr.sort((a,b) =>
          arr.filter(v => v===a).length
        - arr.filter(v => v===b).length
    ).pop();
}

  return (
    <>
      <Helmet>
        <title>Admin Page Views</title>
        <meta property="og:title" content="View Admins" />
        <meta
          property="og:description"
          content="View Admins"
        />
        <meta property="og:url" content={"https://evtgo.com/"} />
        <meta
          property="og:site_name"
          content="View Admins"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
      </Helmet>
      <Container
        className="d-flex justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundColor: "white",
        }}
      >
        <div style={{ width: maxW, marginTop: "25px", marginBottom: "25px" }}>
          <Card className="border-0" style={{ backgroundColor: "transparent" }}>
            <Card.Body className="align-items-center justify-content-center">
              <div style={{ marginTop: "25px", marginBottom: "45px" }}>
                <Card.Title 
                    className="text-center" 
                    style={{fontSize: '25px', fontWeight: 'bold'}}>
                  {pageViews.length} 总点击
                </Card.Title>
                <Card.Text 
                    className="text-center" 
                    style={{fontSize: '20px', fontWeight: 'normal'}}>
                  {startDate.toLocaleDateString('en')}
                </Card.Text>
                <div className="d-flex align-items-center justify-content-center">
                    <Button variant="dark" style={{marginRight: 5}} onClick={()=>{
                        changeCurrentDate(-1)
                    }}>前一天</Button>
                    <Button variant="dark" style={{marginLeft: 5}} onClick={()=>{
                        changeCurrentDate(1)
                    }}>后一天</Button>
                </div>
              </div>

              {isMobile && <div>
                <Card>
                      <Card.Body>
                          <div className="fw-normal">今日总点击</div>
                          <div className="fw-bold mt-2">{pageViews.length}</div>
                      </Card.Body>
                  </Card>
                  <Card>
                      <Card.Body>
                          <div className="fw-normal">今日自然IP数量</div>
                          <div className="fw-bold mt-2">{pageViewData.uniqueIp}</div>
                      </Card.Body>
                  </Card>
                  <Card>
                      <Card.Body>
                          <div className="fw-normal">今日总订单</div>
                          <div className="fw-bold mt-2">{orderCreated}</div>
                      </Card.Body>
                  </Card>
                  <Card>
                      <Card.Body>
                          <div className="fw-normal">今日总用户</div>
                          <div className="fw-bold mt-2">{userCreated}</div>
                      </Card.Body>
                  </Card>
              </div>}

              {!isMobile &&
              <div>
              <Row md={4}>
              <Col>
              <Card>
                  <Card.Body>
                      <div className="fw-normal">总点击</div>
                      <div className="fw-bold mt-2">{pageViews.length}</div>
                  </Card.Body>
              </Card>
              </Col>

              <Col>
              <Card>
                  <Card.Body>
                      <div className="fw-normal">自然IP数量</div>
                      <div className="fw-bold mt-2">{pageViewData.uniqueIp}</div>
                  </Card.Body>
              </Card>
              </Col>

              <Col>
              <Card>
                  <Card.Body>
                      <div className="fw-normal">总订单</div>
                      <div className="fw-bold mt-2">{orderCreated}</div>
                  </Card.Body>
              </Card>
              </Col>
              <Col>
              <Card>
                      <Card.Body>
                          <div className="fw-normal">总用户</div>
                          <div className="fw-bold mt-2">{userCreated}</div>
                      </Card.Body>
                  </Card>
                  </Col>
          </Row>

          <Row md={4} style={{marginTop: 15}}>
              <Col>
              <Card>
                  <Card.Body>
                      <div className="fw-normal">去哪玩总点击</div>
                      <div className="fw-bold mt-2">{pageViewData.tourVisit}</div>
                  </Card.Body>
              </Card>
              </Col>

              <Col>
              <Card>
                  <Card.Body>
                      <div className="fw-normal">去哪玩IP数量</div>
                      <div className="fw-bold mt-2">{pageViewData.uniqueTourIP}</div>
                  </Card.Body>
              </Card>
              </Col>

              <Col>
              <Card>
                  <Card.Body>
                      <div className="fw-normal">去哪玩最多点击</div>
                      <div className="fw-bold mt-2">{pageViewData.mostVisitedTour}</div>
                  </Card.Body>
              </Card>
              </Col>
              <Col>
              <Card>
                      <Card.Body>
                          <div className="fw-normal">去哪玩总下单</div>
                          <div className="fw-bold mt-2">{tourOrderCreated}</div>
                      </Card.Body>
                  </Card>
                  </Col>
          </Row>

          </div>
              }
              <div style={{marginTop: '15px'}}>去哪玩</div>
              <ListGroup>
                  {
                      Object.keys(tourVisitedCount).sort((a,b) => {
                          return tourVisitedCount[b] - tourVisitedCount[a]
                      }).map((tStr)=>{
                          let vCount = tourVisitedCount[tStr]
                          return <ListGroup.Item>{tStr} - {vCount}</ListGroup.Item>
                      })
                  }
              </ListGroup>

              <div style={{marginTop: '15px'}}>搜索词</div>
              <ListGroup>
                  {
                      searchKeywords.map((tStr)=>{
                          return <ListGroup.Item>{tStr}</ListGroup.Item>
                      })
                  }
              </ListGroup>
              
              
              <ListGroup style={{marginTop: '15px'}}>
                  {
                      pageViews.map((viewItm) => {
                          let eveName = ""
                          if (Object.keys(eventRef).includes(viewItm.eventId)){
                              eveName = eventRef[viewItm.eventId].title                         
                          }
                          else{
                              eveName = viewItm.eventId
                          }
                          return <ListGroup.Item>
                          <div className="fw-normal">{viewItm.timestamp.toDate().toLocaleString('en')}</div>
                          <div className="fw-bold">IP: {viewItm.currentIP}</div>
                          <div className="fw-bold" hidden={viewItm.eventId == ""}>Event: {eveName}</div>
                          <div className="fw-bold" hidden={viewItm.eventId == ""}>Location: {viewItm.location}</div>
                        </ListGroup.Item>
                      })
                  }
              </ListGroup>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
