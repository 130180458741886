import React from "react";
import { Card, Container } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";

export default function MessageCenter() {
  const maxW = isMobile ? "100%" : "90%";

  return (
    <>
      <Helmet>
        <title>Event Go Message Center</title>
        <meta property="og:title" content="Riley pricing" />
        <meta
          property="og:description"
          content="Explore the best events and groups in your area"
        />
        <meta property="og:url" content={"https://rileyevents.com/"} />
        <meta
          property="og:site_name"
          content="Riley - Explore the best events and groups in your area"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
      </Helmet>
      <Container
        className="d-flex justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundColor: "white",
        }}
      >
        <div style={{ width: maxW, marginTop: "25px", marginBottom: "25px" }}>
          <Card className="border-0" style={{ backgroundColor: "transparent" }}>
            <Card.Body className="align-items-center justify-content-center">
              <div style={{ marginTop: "25px", marginBottom: "45px" }}>
                <Card.Title className="text-center">
                  <b>Message Center</b>
                </Card.Title>
                <Card.Text className="text-center">
                  For riley organizer
                </Card.Text>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
